import React, { Component } from 'react'

import BlogContainer from '../components/blog-page/PageBlog/BlogContainer'
import BlogCopyRow from '../components/blog-page/PageBlog/BlogCopyRow'
import BlogMediaRowFull from '../components/blog-page/PageBlog/BlogMediaRowFull'
import BlogQuoteRow from '../components/blog-page/PageBlog/BlogQuoteRow'
import BlogRelated from '../components/blog-page/PageBlog/BlogRelated'
import BlogSubscribeRow from '../components/blog-page/PageBlog/BlogSubscribeRow'
import BlogTitleRow from '../components/blog-page/PageBlog/BlogTitleRow'
import { Box } from '@rebass/grid'
import CopyStackRow from '../components/v2/CopyStackRow'
import Coverflow from '../components/v2/Coverflow'
import Image1 from '../images/etc/happy-haus-hero-5.jpg'
import Image2 from '../images/etc/happy-haus-hero-8.jpg'
import { Link } from 'gatsby'
import PageHeader from '../components/v2/PageHeader'
import SEO from '../components/seo'
import styled from 'styled-components'
import { theme } from '../util/style'

const ogImages = [
  `https://happyhaus.com.au${Image1}`,
  `https://happyhaus.com.au${Image2}`,
]

class Page extends Component {
  render() {
    return (
      <>
        <SEO
          title="Philosophy"
          description="Good design means generous proportions, with spaces that flow together to provide views and interest, combined with zoned living, sleeping and family areas."
          images={ogImages}
        />
        <PageHeader title={'Philosophy'} />
        <BlogContainer attachTop pb={['44px', '94px']}>
          <CopyStackRow
            items={[
              {
                heading: 'Why Happy Haus',
                content: (
                  <p>
                    Our in-haus design + build process is the new benchmark for
                    residential projects. We draw from best practices in
                    architecture and construction and our 15+ years of
                    innovation and learning. Our philosophy informs our process
                    and practice and we believe this is why our clients choose
                    to partner with us for one of the most significant projects
                    of their lives.
                  </p>
                ),
              },
            ]}
          />
        </BlogContainer>
        <BlogContainer attachTop attachBottom pb={['44px', '94px']}>
          <Coverflow
            items={[
              {
                type: 'video',
                poster: '/images/coverflow/1.png',
                src: '/videos/coverflow/1.mp4',
              },
              {
                type: 'video',
                poster: '/images/coverflow/2.png',
                src: '/videos/coverflow/2.mp4',
              },
              {
                type: 'video',
                poster: '/images/coverflow/3.png',
                src: '/videos/coverflow/3.mp4',
              },
              {
                type: 'video',
                poster: '/images/coverflow/4.png',
                src: '/videos/coverflow/4.mp4',
              },
              {
                type: 'video',
                poster: '/images/coverflow/5.png',
                src: '/videos/coverflow/5.mp4',
              },
              {
                type: 'video',
                poster: '/images/coverflow/6.png',
                src: '/videos/coverflow/6.mp4',
              },
              {
                type: 'video',
                poster: '/images/coverflow/7.png',
                src: '/videos/coverflow/7.mp4',
              },
              {
                type: 'video',
                poster: '/images/coverflow/11.png',
                src: '/videos/coverflow/11.mp4',
              },
            ]}
          />
        </BlogContainer>
        <BlogContainer pt={['67px', '147px']} pb={['57px', '137px']}>
          <BlogTitleRow
            data={{ title: 'Practice', font: theme.font.fontFamilySerifThin }}
          />
        </BlogContainer>
        <BlogContainer attachTop pb={['44px', '94px']}>
          <CopyStackRow
            items={[
              {
                heading: 'Design + build practice',
                content: (
                  <>
                    <p>
                      Our design + build practice draws from the best in
                      architecture and construction. Our designs balance light,
                      breezes, privacy, and views for living in the subtropics.
                      Every build benefits from our design, and craftsmanship
                      and client experience. In collaborative partnership, our
                      in-haus team ensures there are no service gaps from design
                      to interiors, cost planning, development and construction
                      approvals, building documentation, and construction.
                    </p>
                  </>
                ),
              },
              {
                heading: 'Drawing on experience',
                content: (
                  <>
                    <p>
                      Since 2009, we’ve created homes for many great clients in
                      some of Australia's most amazing locations. Every
                      architect-designed home draws from our vast experience,
                      founded on our collaboration with revered architectural
                      practice Donovan Hill.
                    </p>
                    <p>
                      Ideas culminate in our{' '}
                      <Link to="/haus-series/">Haus Series</Link> – a starting
                      point for conversation and inspiration that can be shaped
                      into your home.
                    </p>
                  </>
                ),
              },
              {
                heading: 'Integrated in-haus team',
                content: (
                  <>
                    <p>
                      Our in-haus teams work closely with directors Lachlan and
                      Rick, who are actively across every stage of the process.
                      Staying connected to the work, team, and client partners
                      is essential for continuous improvement as a business and
                      service. Our client partners take comfort in knowing they
                      have direct access to our integrated design + build teams
                      throughout their projects.
                    </p>
                  </>
                ),
              },
            ]}
          />
        </BlogContainer>
        <BlogContainer attachTop>
          <BlogMediaRowFull
            data={{
              mediaCaption: {
                mediaCaption: (
                  <>
                    North Stradbroke Haus, 2009. The iconic Happy Haus, designed
                    in collaboration with Donovan Hill, is where it all started.
                  </>
                ),
              },
              media: {
                file: {
                  path: '/philosophy/happyhaus-16x9-photo-north-stradbroke-JL-133.png',
                },
              },
            }}
          />
        </BlogContainer>

        <BlogContainer attachTop pb={['46px', '86px']}>
          <BlogQuoteRow
            data={{
              content: (
                <>
                  <p>
                    If you’re considering a Happy Haus or about to embark on a
                    new haus project, we can’t wait to say,{' '}
                    <i>“Welcome home!”</i>
                  </p>
                  <h5>LACHLAN & RICK</h5>
                </>
              ),
            }}
          />
        </BlogContainer>

        <BlogContainer attachTop pb={['42x', '92px']}>
          <ImageWrapper>
            <Box width={['480px']}>
              <img
                src="/philosophy/lachlan-and-rick.jpg"
                alt="Lachlan and Rick"
                style={{ display: 'block' }}
                width={480}
                height={720}
              ></img>
            </Box>
          </ImageWrapper>
        </BlogContainer>

        <BlogContainer borderTop pt={['67px', '147px']} pb={['57px', '137px']}>
          <BlogTitleRow
            data={{ title: 'Principles', font: theme.font.fontFamilySerifThin }}
          />
        </BlogContainer>
        <BlogContainer attachTop>
          <BlogCopyRow
            data={{
              mediaCaption: {
                mediaCaption: 'Owners Club',
              },
              image: {
                file: {
                  path: '/philosophy/happyhaus-gallery-2023-03-15-photo-silex-JG-1010426.jpg',
                },
              },
              copyHtml: (
                <div>
                  <h3>Process</h3>
                  <p>
                    Every Happy Haus goes through the same process simply
                    because it works. Our clients benefit from a stated workflow
                    designed to provide the support and information required to
                    make informed collaborative decisions at the right times.
                  </p>
                  <p>
                    We do this because, as more detailed information becomes
                    available, we want both us and our clients to have more
                    insight upfront with fewer surprises. This process enables
                    better decision-making about ideas, aspirations, and
                    selections throughout the design process.{' '}
                  </p>
                  <p>
                    Listening to and respecting everyone’s input in the process
                    is paramount. As experts, we believe you have chosen us to
                    offer opinions and advice you can trust. Respecting the
                    process means focusing on the right aspects at the right
                    time to maintain momentum and achieve joint success.
                  </p>
                </div>
              ),
            }}
          />
        </BlogContainer>
        <BlogContainer>
          <BlogCopyRow
            data={{
              mediaCaption: {
                mediaCaption: 'James Haus',
              },
              reverse: true,

              video: {
                playbackId: '9gUpQwC4XAawbDbGFeJBjdhImT1BH01pjBzGfiEF4xz8',
              },
              copyHtml: (
                <div>
                  <h3>Design</h3>
                  <p>
                    Good design is at the forefront of our thinking when we
                    start every Happy Haus project. Orientation, site
                    responsiveness, and the opportunities for breezes and
                    ventilation are all factors we consider first, not just what
                    plan works on a site.
                  </p>
                  <p>
                    What makes our design-and-build process unique is that while
                    our team takes the time to understand the site, the
                    opportunities, and the constraints, we use construction
                    overlays to inform design decisions and provide real-time
                    feedback about their impact on a project.
                  </p>
                  <p>
                    When good home design goes beyond the all-important
                    practical considerations, it can significantly impact the
                    ambience and internal dynamics of the home. For us, this
                    involves balanced proportions, interconnected spaces that
                    offer views and visual interest, shared spaces, and places
                    for retreat.
                  </p>
                </div>
              ),
            }}
          />
        </BlogContainer>
        <BlogContainer>
          <BlogCopyRow
            data={{
              mediaCaption: {
                mediaCaption: 'Windsor Haus',
              },
              image: {
                file: {
                  path: '/philosophy/happyhaus-gallery-2024-02-22-photo-flower-1110741.jpg',
                },
              },
              copyHtml: (
                <div>
                  <h3>Quality</h3>
                  <p>
                    We consider all kinds of projects and assess each
                    individually to carefully manage the number of homes we
                    build each year. Our aim is to balance our company scale and
                    project schedule for benchmark quality and consistency
                    across our practice, process and client experience.
                  </p>
                  <p>
                    Researching, communicating, and decision-making in pursuit
                    of quality and accuracy takes time and a team of experts and
                    trusted specialists. Our projects and clients are afforded
                    meaningful time and attention so our team can guide them
                    along the right path for designing a haus that will become
                    their home.
                  </p>
                  <p>
                    Another reason we maintain an intentional scale is to
                    continue working with the same tradespeople and suppliers on
                    every project. We've been working with many trusted
                    collaborators for over 15 years because they know exactly
                    what it takes to bring a Happy Haus together.
                  </p>
                </div>
              ),
            }}
          />
        </BlogContainer>
        <BlogContainer>
          <BlogCopyRow
            data={{
              mediaCaption: {
                mediaCaption: 'Y1 Haus',
              },
              reverse: true,
              image: {
                file: {
                  path: '/philosophy/happyhaus-gallery-2022-09-20-photo-orari-JG-1080086.jpg',
                },
              },
              copyHtml: (
                <div>
                  <h3>Sustainability</h3>
                  <p>
                    Sustainability for the environment and our industry is an
                    ongoing challenge we embrace within our business philosophy.
                    We are committed to the ongoing research, design,
                    development, and construction of homes sensitive to both.
                  </p>
                  <p>
                    We design and orient homes to passively capture breezes and
                    light for optimal living in the subtropics. To minimise our
                    impact, we seek out sustainable products and practices. This
                    includes our thinking and actions, from the design of our
                    homes to their ongoing performance.
                  </p>
                </div>
              ),
            }}
          />
        </BlogContainer>
        <BlogContainer>
          <BlogCopyRow
            data={{
              mediaCaption: {
                mediaCaption: 'Haus Tarragindi',
              },
              image: {
                file: {
                  path: '/philosophy/happyhaus-gallery-2024-02-15-photo-tarragindi-1110214.jpg',
                },
              },
              copyHtml: (
                <div>
                  <h3>Partnership</h3>
                  <p>
                    Choosing who creates your home will be one of the most
                    important partnerships of their lives. While designing and
                    building a home is a partnership, we will prepare you at
                    each stage, so you know what is coming, what you need to do,
                    and what outcome we are working towards.
                  </p>
                  <p>
                    We ask for openness and honesty. Our role in this
                    partnership is to manage all the complexities to ensure the
                    experience is delivered without any service gaps. As
                    partners, we share in the impact of changes and how we
                    address them to maintain positivity and momentum.
                  </p>
                  <p>
                    With what we’ve seen in the construction industry in recent
                    years, including volatile material pricing and trade
                    shortages, our emphasis on partnership realises the shared
                    success of our projects. We are a trusted partner because we
                    perpetuate outcomes for our partner clients.
                  </p>
                </div>
              ),
            }}
          />
        </BlogContainer>
        <BlogContainer>
          <BlogSubscribeRow
            data={{
              image: { file: { path: '/philosophy/subscribe-row.jpg' } },
              copyHtml: 'Read about designing for living in the subtropics',
              buttonText: 'Download',
              theme: 'Single Level Living',
              hubspotConversionType: 'Subtropics',
            }}
          />
        </BlogContainer>
        <BlogContainer pb={['43px', '93px']}>
          <BlogMediaRowFull
            data={{
              style: 'inset',
              media: {
                file: {
                  path: '/philosophy/happyhaus-gallery-2024-02-15-photo-tarragindi-1110220.jpg',
                },
              },
            }}
          />
        </BlogContainer>
        <BlogContainer attachTop pb={['36px', '86px']}>
          <BlogQuoteRow
            data={{
              content: (
                <>
                  <p>
                    Our courtyard home is a sun trap surrounded by plenty of
                    spaces for shade and retreat. Everything flows so well. We
                    love it here, it’s the best decision we’ve ever made.
                  </p>
                  <h5>OCEAN SHORES HAUS</h5>
                </>
              ),
            }}
          />
        </BlogContainer>
        <BlogRelated
          data={{
            relatedPosts: [
              {
                slug: 'top-sales-results-in-new-farm-and-tarragindi',
                title: 'Top sales results in New Farm and Tarragindi',
                category: ['Noteworthy'],
                thumbnail: {
                  file: {
                    path: '/images/custom-haus/happyhaus-gallery-2024-02-02-photo-james-1100859.jpg',
                  },
                },
              },
              {
                title: 'Making more neighbourhood memories',
                slug: 'silex-haus-a-legacy-of-memories',
                category: ['Owners Club'],
                thumbnail: {
                  file: {
                    path: '/images/custom-haus/happyhaus-gallery-2023-03-15-photo-silex-JG-1010415.jpg',
                  },
                },
              },
            ],
          }}
        />
      </>
    )
  }
}

export default Page

const ImageWrapper = styled(Box)`
  display: flex;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
  }
`
